import React, {useContext} from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {DataContext} from "../context/dataContext";
import {Typography} from "@material-ui/core";
import Tooltip from '@mui/material/Tooltip';


export default function SwitchGroup() {

    const {
        switchData, setSwitchData, 
        setTextAreaValue, setShowVariants,
        setShowNickingTable,
    } = useContext(DataContext)

    const templateTexts = [


        "GCCTGGAGGTGTCTGGGTCCCTCCCCCACCCGACTACTTCACTCTCTGTCCTC" +
        "TCTGCCCAGGAGCCCAGGATGTGCGAGTTCAAGTGGCTACGGCCGA(G/C)GT" +
        "GCGAGGCCAGCTCGGGGGCACCGTGGAGCTGCCGTGCCACCTGCTGCCACC" +
        "TGTTCCTGGACTGTACATCTCCCTGGTGACCTGGCAGCGCCCAGATGCACCTG" +
        "CGAACCACCAGAATGTGGCCGC",

        "GCCTGGAGGTGTCTGGGTCCCTCCCCCACCCGACTACTTCACTCTCTGTCCTCTCTGCCCAGGAGCCCAGGATGTGCGAGTTCAAGTGGCTACGGCCGA(GTG/CAA)GTGCGAGGCCAGCTCGGGGGCACCGTGGAGCTGCCGTGCCACCTGCTGCCACCTGTTCCTGGACTGTACATCTCCCTGGTGACCTGGCAGCGCCCAGATGCACCTGCGAACCACCAGAATGTGGCCGC",


        "GCCTGGAGGTGTCTGGGTCCCTCCCCCACCCGACTACTTCACTCTCTGTCCT" +
        "CTCTGCCCAGGAGCCCAGGATGTGCGAGTTCAAGTGGCTACGGCCGA(-G" +
        ")GTGCGAGGCCAGCTCGGGGGCACCGTGGAGCTGCCGTGCCACCTGCTGCC" +
        "ACCTGTTCCTGGACTGTACATCTCCCTGGTGACCTGGCAGCGCCCAGATGCA" +
        "CCTGCGAACCACCAGAATGTGGCCGC",


        "GCCTGGAGGTGTCTGGGTCCCTCCCCCACCCGACTACTTCACTCTCTGTCC" +
        "TCTCTGCCCAGGAGCCCAGGATGTGCGAGTTCAAGTGGCTACGGCCGA(" +
        "+T)GTGCGAGGCCAGCTCGGGGGCACCGTGGAGCTGCCGTGCCACCTGCT" +
        "GCCACCTGTTCCTGGACTGTACATCTCCCTGGTGACCTGGCAGCGCCCAGA" +
        "TGCACCTGCGAACCACCAGAATGTGGCCGC",


    ]

    const handleChange = index => {
        setShowNickingTable(false)
        if (switchData[index]) {
            let copy = [false, false, false];
            setSwitchData([...copy])
            setTextAreaValue("");
        } else {
            let copy = [false, false, false];
            copy[index] = true;
            setSwitchData([...copy])
            setTextAreaValue(() => templateTexts[index])

        }
        

        setShowVariants(() => false)
    }


    return (
        <>
            <FormGroup row style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "5%"
            }}>

                <Typography variant={"h6"}>
                    Example:
                </Typography>

                <Tooltip title={<Typography fontSize={30} align="center">e.g. G to C edit: ...(G/C)...</Typography>} arrow>
                    <div>
                        <FormControlLabel
                            control={
                                <Switch
                                    color="primary"
                                    checked={switchData[0]}
                                    onChange={() => {
                                        handleChange(0)
                                    }}

                                />
                            }
                            label="Replacement"
                        />
                    </div>
                </Tooltip>
                
                <Tooltip title={<Typography fontSize={30} align="center">e.g. GTG to CAA edit: ...(GTG/CAA)...</Typography>} arrow>
                    <div>
                        <FormControlLabel
                            control={
                                <Switch
                                    color="primary"
                                    checked={switchData[1]}
                                    onChange={() => {
                                        handleChange(1)
                                    }}
                                />
                            }
                            label="Multi-bp"
                        />
                    </div>
                </Tooltip>


                <Tooltip title={<Typography fontSize={30} align="center">e.g. G deletion edit: ...(-G)... </Typography>} arrow>
                    <div>
                        <FormControlLabel
                            control={
                                <Switch
                                    name="checkedB"
                                    color="primary"
                                    checked={switchData[2]}
                                    onChange={() => {
                                        handleChange(2)
                                    }}
                                />
                            }
                            label="Deletion"
                        />
                    </div>
                </Tooltip>

                <Tooltip title={<Typography fontSize={30} align="center">e.g. T insertion edit: ...(+T)...</Typography>} arrow>
                    <div>
                        <FormControlLabel
                            control={
                                <Switch
                                    name="checkedB"
                                    color="primary"
                                    checked={switchData[3]}
                                    onChange={() => {
                                        handleChange(3)
                                    }}
                                />
                            }
                            label="Insertion"
                        />
                    </div>
                </Tooltip>

            </FormGroup>
        </>
    );
}
