import React, {useContext} from 'react';
import {TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {DataContext} from "../context/dataContext";

const useStyles = makeStyles(() => ({
    inputStyles: {
        fontSize: "1.3rem"
    }
}))

export default function TextArea() {

    const {textAreaValue, setTextAreaValue, setShowVariants, setSwitchData, setShowNickingTable} = useContext(DataContext)
    //console.log(switchState)
    const classes = useStyles();
    return (
        <>
            <TextField
                style={{
                    backgroundColor: "#E4E4E4"
                }}
                fullWidth
                multiline
                minRows={20}

                InputLabelProps={{
                    style: {color: '#8D8F9C',},
                }}
                InputProps={{disableUnderline: true}}
                variant={"filled"}
                inputProps={{
                    className: classes.inputStyles
                }}
                placeholder='Enter sequence in the following format:&#10;(100bp)-NNN (Sequence *before* edit / Sequence *after* edit) NNN-(100bp)&#10;Put unchanged bases flanking the edit *outside* of brackets (e.g. NNNA(G/C)TNNN instead of NNN(AGT/ACT)NNN)&#10;For deletions or insertions use "(-N)" or "(+N)" as placeholder.&#10;For further details select examples above.'
                value={textAreaValue}
                onChange={e => {
                    setSwitchData([false, false, false])
                    setShowVariants(false)
                    setShowNickingTable(false)
                    setTextAreaValue(e.target.value)
                    //console.log(switchState)
                }}
                id="textAreaField"
                name="textAreaField"
            />

        </>
    );
}
